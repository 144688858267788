<template>
  <div style="display: grid; justify-content: center; align-items: center;" v-if="!insertedCode">
    <md-radio v-model="hasCode" value="false" class="promo-text">Promotion Code</md-radio>
    <div v-if="hasCode == 'false'" class="md-layout-item md-small-size-60">
        <md-field class="disabled">
        <label for="code" class="promo-text">Promotion Code</label>
        <md-input name="code" id="code" v-model="code" />
      </md-field>
      <md-button class="verify-promo-button" @click="checkPromotionCode" :disabled='loading'>
        Verify
       </md-button>

    </div>

    <md-radio v-model="hasCode" value="true" class="promo-text">Subscribe</md-radio>
    <paypal-buttons v-if="hasCode == 'true'" :env="mode" :createSubscription="createSubscription" :onApprove="paypalOnApprove"
      :on-error="paypalOnError" :style-object="style" style="width: 250px;" />
  </div>

  <div style="display: grid; justify-content: center; align-items: center; margin-top: 50px;" v-else>
    <paypal-buttons :env="mode" :createSubscription="createSubscription" :onApprove="paypalOnApprove"
      :on-error="paypalOnError" :style-object="style" style="width: 250px;" />
  </div>
</template>

<script>
import Vue from 'vue';
import PaymentsService from '@/services/payments.service';
import SubscriptionCodesService from '@/services/subscriptionCodes.service';
import { GET_AUTH_USER_TABS } from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';
import {
  MODE, SANDBOX_CLIENT_ID, LIVE_CLIENT_ID, CLIENT_ID,
} from '@/payments';

export default {
  data() {
    return {
      style: {
        layout: 'vertical',
        size: 'responsive',
        shape: 'pill',
        label: 'paypal',
      },
      hasCode: 'true',
      code: null,
      subscriptionPlan: null,
      discountedPlanId: null,
      insertedCode: false,
      mode: MODE,
      client: CLIENT_ID,
      credentials: {
        sandbox: SANDBOX_CLIENT_ID,
        production: LIVE_CLIENT_ID,
      },
    };
  },
  methods: {
    createSubscription(data, actions) {
      return actions.subscription.create({
        plan_id: this.discountedPlanId ?? this.subscriptionPlan.plan_id,
      });
    },
    paypalOnApprove(data) {
      this.savePayment(data);
    },
    paypalOnError() {
      this.showErrorMsg('Something went wrong');
    },
    checkPromotionCode() {
      const formData = new FormData();
      formData.append('code', this.code);
      formData.append('plan_id', this.subscriptionPlan.id);
      SubscriptionCodesService.checkCode(formData).then((res) => {
        this.$toasted.show('Code is approved.', {
          type: 'success',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
        });
        this.discountedPlanId = res.data.data[0].discounted_plan_id_in_paypal;
        this.insertedCode = true;
      })
        .catch((error) => {
          this.$toasted.show(error, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
    savePayment(data) {
      const subscriptionData = data;
      subscriptionData.subscriptionType = this.subscriptionPlan.type;
      PaymentsService.createSubscription(subscriptionData)
        .then(() => {
          this.$toasted.show('Your purchase was successful. Thank you!', {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.$store.dispatch(GET_AUTH_USER_TABS).then();
          this.$router.push({
            name: 'thanks',
          });
        })
        .catch((error) => {
          this.showErrorMsg(error);
        });
    },
    showErrorMsg(msg) {
      this.$toasted.show(msg, {
        type: 'error',
        position: 'top-right',
        duration: 5000,
        icon: 'alert',
      });
      this.$router.push({
        name: 'billing',
      });
    },
  },
  mounted() {
    const data = this.$route.params;
    this.subscriptionPlan = data.subscriptionPlan;
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  components: {
    'paypal-buttons': window.paypal.Buttons.driver('vue', Vue),
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/purchase-page.scss';
</style>
